import { useContext, useEffect, useMemo, useState } from 'react';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  review_autoTipSettingsSelector as autoTipSettingsSelector,
  disputes_gotoFirstDisputeStep as getDisputeRoute,
  review_reviewService as reviewService,
} from '@lawnstarter/customer-modules/stores/modules';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';
import { ServiceType, TipDisplayMode } from '@lawnstarter/ls-react-common/enums';

import { ConfirmationType } from '@src/components/molecules/TipAmountConfirmation/enums';
import { TipAmountOptions } from '@src/components/organisms';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import { BadReviewAction } from './enums';

import type { SelectOptionItemKey } from '@lawnstarter/ls-react-common/molecules';
import type { ScheduleEventReview } from '@lawnstarter/ls-react-common/types';
import type { TipAmountOption } from '@src/components';
import type { UseServiceReviewModalProps } from './types';

export function useServiceReviewModal({
  propertyId,
  scheduleId,
  scheduleEventId,
}: UseServiceReviewModalProps) {
  const dispatch = useDispatch();
  const navigation = useRouteNavigation();

  const modalContext = useContext(ModalContext);

  const isLoading = useSelector(
    ({ schedules, properties }) =>
      schedules.loadingStatus.isLoading || properties.loadingStatus.isLoading,
  );

  const isUpdating = useSelector(({ review }) => review.loadingStatus.isUpdating);

  const schedule = useSelector(({ schedules }) => schedules.schedulesById[scheduleId]);
  const autoTipSettings = useSelector(autoTipSettingsSelector);

  const [alreadyRated, setAlreadyRated] = useState<ScheduleEventReview | null>(null);
  const [hasSubmitted, setSubmitted] = useState<boolean>(false);
  const [issue, setIssue] = useState<SelectOptionItemKey | null>(null);
  const [rating, setRating] = useState(0);
  const [showAutoTipChoice, setShowAutoTipChoice] = useState<boolean>(false);
  const [showTipConfirmation, setShowTipConfirmation] = useState<ConfirmationType | null>(null);
  const [reviewId, setReviewId] = useState<number | null>(null);
  const [tip, setTip] = useState<TipAmountOption>(TipAmountOptions.OptionPercent20);
  const [tipAmount, setTipAmount] = useState<number | null>(null);
  const [tipPercentage, setTipPercentage] = useState<number | null>(null);
  const [tipShow, setTipShow] = useState<TipDisplayMode | null>(null);

  const isBadRating = useMemo(() => Boolean(rating && rating < 4), [rating]);

  const isGoodRating = useMemo(() => Boolean(rating && rating > 3), [rating]);

  const scheduleEvent = useMemo(() => {
    const scheduleEvents = schedule?.recent_schedule_events ?? schedule?.scheduleevents ?? [];
    return scheduleEvents.find(({ id }) => id === Number(scheduleEventId));
  }, [schedule, scheduleEventId]);

  const servicePrice = useMemo(() => scheduleEvent?.price ?? 0, [scheduleEvent]);

  const serviceType = useMemo(
    () =>
      schedule?.service?.str_id ??
      schedule?.qualityassurance?.service?.str_id ??
      ServiceType.LawnMowing,
    [schedule],
  );

  const isDisputableService = useMemo(
    () => [ServiceType.LawnMowing, ServiceType.PoolCleaning].includes(serviceType),
    [serviceType],
  );

  const tipMatching = useMemo(
    () => scheduleEvent?.contractor?.tier === 'platinum',
    [scheduleEvent],
  );

  const name = useMemo(
    () =>
      scheduleEvent?.contractor?.user?.first_name ||
      scheduleEvent?.contractor?.name ||
      t('yourCrew'),
    [scheduleEvent],
  );

  const isIssueRequired = useMemo(
    () => isBadRating && isDisputableService,
    [isBadRating, isDisputableService],
  );

  useEffect(() => {
    if (!hasSubmitted && scheduleEvent?.reviews?.length) {
      const review = scheduleEvent.reviews[0];
      setAlreadyRated(review);
    }
  }, [hasSubmitted, scheduleEvent]);

  function onAutoTipChoice(enabled?: boolean) {
    setShowAutoTipChoice(false);

    if (enabled !== undefined) {
      setShowTipConfirmation(enabled ? ConfirmationType.AutoTip : ConfirmationType.OneTimeTip);
    }
  }

  function onIssueSelect(key?: SelectOptionItemKey) {
    setIssue(key ?? null);
  }

  function onRatingChange(value: number) {
    setRating(value);

    setIssue(null);
    setTip(TipAmountOptions.OptionPercent20);

    if (autoTipSettings.enabled && value > 3) {
      setShowTipConfirmation(ConfirmationType.AutoTip);
      setTipAmount(autoTipSettings.amount ? autoTipSettings.amount / 100 : null);
      setTipPercentage(autoTipSettings.percentage ? autoTipSettings.percentage * 100 : null);
      setTipShow(autoTipSettings.percentage ? TipDisplayMode.Percentage : TipDisplayMode.Amount);
      return;
    }

    setShowTipConfirmation(null);
    setTipAmount(null);
    setTipPercentage(value < 4 ? null : 20);
    setTipShow(value < 4 ? null : TipDisplayMode.Percentage);
  }

  function onTipSelect(option: TipAmountOption) {
    setTip(option);

    if (option.key === TipAmountOptions.OptionNone.key) {
      setTipAmount(null);
      setTipPercentage(null);
      setTipShow(null);
      return;
    }

    if (option.key === TipAmountOptions.OptionOther.key) {
      setTipAmount(option.amount || null);
      setTipShow(TipDisplayMode.Amount);
      setTipPercentage(null);
      return;
    }

    setTipPercentage(option.percentage);
    setTipShow(TipDisplayMode.Percentage);
    setTipAmount(null);
  }

  async function onSubmit() {
    if (!rating) {
      return;
    }

    if (isIssueRequired && !issue) {
      return;
    }

    if (isGoodRating) {
      const hasAnyTip = tip.key !== TipAmountOptions.OptionNone.key;

      if (hasAnyTip && !showTipConfirmation) {
        if (autoTipSettings.enabled) {
          setShowTipConfirmation(ConfirmationType.AutoTip);
          return;
        }

        setShowAutoTipChoice(true);
        return;
      }
    }

    setSubmitted(true);

    const response = await dispatch(
      reviewService({
        property_id: propertyId,
        schedule_id: scheduleId,
        scheduleevent_id: scheduleEventId,
        rating,
        issue_type: issue,
        tip_amount: tipAmount,
        tip_percentage: tipPercentage,
        tip_show: tipShow,
      }),
    );

    if (!response || !response.success) {
      modalContext.hide();
      return navigation.navigate(WebRoutes.services);
    }

    setReviewId(response.review_id);

    if (isGoodRating) {
      return;
    }

    modalContext.hide();

    switch (response.action) {
      case BadReviewAction.Disputed: {
        const disputeRoute = dispatch(getDisputeRoute());
        return navigation.navigate(WebRoutes[disputeRoute.nextStep as keyof typeof WebRoutes], {
          params: {
            propertyId,
            scheduleEventId,
            scheduleId,
            willRedistribute: disputeRoute.willRedistribute ?? false,
          },
        });
      }

      case BadReviewAction.OfferCycleChange: {
        return navigation.navigate(WebRoutes.changeServiceFrequencyCycleScreen, {
          params: {
            propertyId,
            scheduleEventId,
            scheduleId,
          },
        });
      }

      case BadReviewAction.PropertyDamage: {
        return navigation.navigate(WebRoutes.reportDamageScreen, {
          params: {
            propertyId,
            scheduleEventId,
            scheduleId,
          },
        });
      }

      default: {
        return navigation.navigate(WebRoutes.services);
      }
    }
  }

  return {
    alreadyRated,
    autoTipSettings,
    hasSubmitted,
    isBadRating,
    isGoodRating,
    isIssueRequired,
    isLoading,
    isDisputableService,
    issue,
    isUpdating,
    modalContext,
    name,
    onAutoTipChoice,
    onIssueSelect,
    onRatingChange,
    onSubmit,
    onTipSelect,
    rating,
    reviewId,
    schedule,
    scheduleEvent,
    servicePrice,
    setShowTipConfirmation,
    showAutoTipChoice,
    showTipConfirmation,
    tip,
    tipAmount,
    tipMatching,
    tipPercentage,
    tipShow,
  };
}
